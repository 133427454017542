import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptImageOne from "./assets/PromptImageOne.webp"
import PromptImageOneFullSize from "./assets/PromptImageOneFullSize.webp"
import MediaContentOne from "./assets/MediaContentOne.webp"

import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"

import PromptIconFour from "./assets/PromptIconFour.png"

import PromptImageTwo from "./assets/PromptImageTwo.webp"
import PromptImageTwoFullSize from "./assets/PromptImageTwoFullSize.webp"

import PromptIconFive from "./assets/PromptIconFive.png"

import PromptImageThree from "./assets/PromptImageThree.webp"
import PromptImageThreeFullSize from "./assets/PromptImageThreeFullSize.webp"

import PromptIconSix from "./assets/PromptIconSix.png"
import MediaContentFour from "./assets/MediaContentFour.webp"

import PromptIconSeven from "./assets/PromptIconSeven.png"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "Хотели сделать полезную еду доступной",
		textarea: (
			<span>
				<i>Андрей:</i> Мысль открыть кафе появилась в 2018 году. В России в то
				время мало кто знал про поке, а в Петербурге не было никакой быстрой
				полезной еды. Если ты хотел перекусить в 10–11 вечера, то выбор был
				небольшим — фастфуд, шаверма, пицца и паста.
				<br />
				<br />
				Я понял, что хочу сделать проект, где полезная еда будет готовиться
				быстро, стоить недорого и станет частью повседневной жизни, в том числе
				моей. Поке идеально подходило как блюдо, в котором есть баланс пользы и
				вкуса. Впервые попробовал его на Бали. Мне понравился этот концепт
				быстрой еды для сёрферов: блюдо быстро наполняет энергией и даёт
				возможность снова заняться спортом.
				<br />
				<br />
				<i>Настя:</i> С поке я познакомилась тоже в путешествии — открыла его
				для себя во Вьетнаме. Вроде бы просто рис и начинки, но игра вкусов
				неожиданная. Я поела один раз и потом мне хотелось пробовать его везде.
			</span>
			// <>
			// 	<span className={styles.contentDesktop}>
			// 		Франчайзинг — это этап развития проекта, к которому стремится
			// 		большинство рестораторов.
			// 		<br />
			// 		Мы поможем настроить систему автоматизации таким образом, чтобы
			// 		запускать новые точки было легко.
			// 	</span>
			// 	<span className={styles.contentTablet}>
			// 		Франчайзинг — это этап развития проекта, к которому стремится
			// 		большинство рестораторов.
			// 		<br />
			// 		Мы поможем настроить систему автоматизации таким образом, чтобы
			// 		запускать новые точки было легко.
			// 	</span>
			// 	<span className={styles.contentMobile}>
			// 		Франчайзинг — это этап развития проекта, к которому стремится
			// 		большинство рестораторов. Мы поможем настроить систему автоматизации
			// 		таким образом, чтобы запускать новые точки было легко.
			// 	</span>
			// </>
		),
		prompt: (
			<div className={styles.promptTypeOne}>
				<img src={PromptIconOne} alt="Иконка 1" loading="lazy" />
				<p>
					Концепт — это хорошо, но бренд тоже важен, и в его разработку стоит
					вложиться
				</p>
			</div>
		),
	},
	{
		heading: "С чего начали, открываясь без опыта",
		textarea: (
			<span>
				<i>Андрей:</i> Многие ребята, которые открывают заведения, уже проходили
				какой-то путь в общепите — работали бариста, официантами, управляющими.
				Ни у меня, ни у Насти такого опыта не было. Чтобы хоть как-то
				познакомиться с этой сферой, мы пошли на мастер-класс, который проводил
				поставщик оборудования для ресторанов.
				<br />
				<br />
				Но начали мы не с меню. Первым делом разработали бренд-платформу и
				фирменный стиль будущего кафе. Концепт — это хорошо, но бренд тоже
				важен, и в его разработку стоит вложиться. Как раз совпало, что
				познакомились с ребятами из брендингового агентства, и они нам с этим
				помогли. Дальше уже составили финансовую модель и бизнес-план.
				<br />
				<br />
				Параллельно искали место для открытия, на это ушло три месяца. Помещение
				на Гороховой улице соответствовало всем требованиям: хороший трафик,
				подходящая аудитория и большие окна. Из минусов — была разруха и нам
				предстоял большой ремонт.
				<br />
				<br />
				<i>Настя:</i> Перед тем, как начать ремонт, мы сделали дизайн-проект.
				Наша идея — создать атмосферу, будто гость идёт вдоль тротуара в
				Калифорнии и наше кафе для него — фудтрак, где можно взять еду. Отсюда
				длинная барная стойка, тротуарная брусчатка и граффити на стенах. Всё
				это создаёт ощущение «уличности».
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeTwo, styles.prompt__pos1)}>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={PromptImageOneFullSize}
						className={styles.media}
					>
						<img
							src={PromptImageOne}
							alt="Помещение для открытия кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>
				<p>
					Хороший трафик
					<br />
					и большие окна
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentOne}
						className={styles.media}
					>
						<img
							src={MediaContentOne}
							alt="интерьер кафе oh my bowl"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>
					Тротуарная брусчатка и граффити на стенах —
					<br />
					всё как в Калифорнии
				</p>
			</div>
		),
	},
	{
		heading:
			"День до открытия — мы остаёмся без шефа, меню и технологических карт",
		textarea: (
			<span>
				<i>Андрей:</i> Нам нужен был шеф-повар, который проработает меню и
				настроит работу кухни. Знакомые дали контакт такого специалиста, я
				рассказал ему про концепцию: договорились, что он сделает проработку за
				50 000 рублей. Позже я узнал, что такая проработка называется фудтест.
				Это этап найма, когда специалист показывает свои навыки, а владельцы
				заведения оценивают, нравятся ли его блюда. В общепите за фудтест не
				принято платить. Шеф приготовил блюда, нам всё понравилось. Но потом он
				передумал и сказал, что дальше готов работать только за 200 000 рублей.
				Это нас не устраивало, и мы стали искать нового шефа.
				<br />
				<br />
				Обратились за помощью к знакомому эйчару. Путём многочисленных фудтестов
				выбрали кандидата. Но с ним потом тоже не сложилось. Готовил он вкусно,
				но постоянно опаздывал, переносил сроки, был максимально
				безответственным человеком. В конце концов, мы расстались, расплатились
				с ним, но у него осталось наше оборудование. Через время он сказал, что
				его квартиру ограбили и техника пропала. Как потом оказалось,
				мы не единственные, кто пострадал от действий этого человека — было
				много похожих историй с его участием.
				<br />
				<br />
				Тогда я усвоил урок: чтобы не попадать впросак, важно собирать отзывы о
				работе топ-персонала у бывших работодателей.
			</span>
		),
		prompt: (
			<div className={styles.promptTypeOne}>
				<img src={PromptIconTwo} alt="Иконка 2" loading="lazy" />
				<p>
					Чтобы не попасть впросак, собирайте отзывы о персонале у бывших
					работодателей
				</p>
			</div>
		),
	},
	{
		textarea: (
			<span>
				<i>Настя:</i> Это был единственный раз, когда я плакала из-за проблем в
				бизнесе. Шеф выставлял всё так, будто это мы что-то неправильно делаем.
				Очень легко начать сомневаться в себе, когда у тебя мало опыта и это
				твой первый проект — особенно, если кто-то давит на твою
				некомпетентность.
				<br />
				<br />
				Открытие пришлось сместить на месяц. Мы заплатили за аренду, но целый
				месяц не работали. Из-за факапов мы превысили закладываемый бюджет:
				планировали уложиться в 1,5 млн рублей, но потратили 10 млн.
			</span>
		),
		prompt: (
			<div className={styles.promptTypeOne}>
				<img src={PromptIconThree} alt="Иконка 3" loading="lazy" />
				<p>
					Из-за факапов превысили бюджет: вместо 1,5 млн рублей потратили 10 млн
				</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent__container}>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image3"
							href={MediaContentTwo}
							className={styles.media}
						>
							<img src={MediaContentTwo} alt="поке боулы" loading="lazy" />
						</a>
					</Fancybox>

					<p>
						Только качественные
						<br />
						продукты
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image4"
							href={MediaContentThree}
							className={styles.media}
						>
							<img
								src={MediaContentThree}
								alt="продукты для приготовления поке"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p>Баланс пользы и вкуса</p>
				</div>
			</div>
		),
	},
	{
		heading: "Сделали рекордную кассу благодаря «реалити про запуск»",
		textarea: (
			<span>
				<i>Настя:</i> До кафе у нас было маркетинговое агентство, и этот опыт
				сильно помог. Я видела всю систему с точки зрения маркетинга. Например,
				понимала, что нужно сделать хороший интерьер, потому что люди любят
				фотографировать. Знала, что нельзя сделать плохой продукт — ты можешь
				сколько угодно вкладывать во всё остальное, но если у тебя плохая еда,
				люди перестанут ходить.
				<br />
				<br />
				Мы заранее завели аккаунт в Инстаграме* и начали показывать, как
				открываем кафе без опыта. К моменту запуска у нас было 2 500
				подписчиков. Люди ждали нашего открытия. За первый месяц мы сделали
				рекордную кассу благодаря «реалити» про запуск.
				<br />
				<br />
				<strong>Нужно заниматься маркетингом ещё до открытия.</strong> Если вы
				начали работать, а людей нет, можно начать что-то делать — раздавать
				листовки, включать рекламу, но время уже будет упущено. Вы потеряли
				аудиторию, которая могла быть лояльна к проекту. Вы не заработали в
				первые месяцы, а надо платить аренду и зарплаты. Это может быть
				критичной ошибкой.
				<br />
				<br />
				Важно показывать в соцсетях проекта себя, команду и что происходит на
				кухне. Клиенты должны видеть, что за проектом стоят живые люди. Кажется,
				это никому не нужно, но практика показывает обратное — людям это
				интересно. Они хотят видеть, какие задачи и проблемы возникают, и как вы
				с ними справляетесь. Появляется доверие и лояльность. Дальше люди
				приходят в кафе, и если у вас вкусная еда, начинает работать сарафанный
				маркетинг — новые гости приходят по рекомендациям. Но всё начинается
				именно с маркетинга до открытия.
				<br />
				<br />
				Для продвижения мы также делаем партнёрки с проектами со схожей
				аудиторией. Например, со студией йоги или с цветочным магазином. Просто
				сделать посты друг о друге и провести совместный конкурс — не наш
				вариант. Это часто привлекает людей, которые хотят что-то получить
				бесплатно и скорее всего потом к тебе не придут, и вообще твоим проектом
				интересоваться не будут. Мы стараемся делать какой-то совместный движ —
				интервью, прямой эфир, ходить друг к другу в гости, чтобы реально
				заинтересовать людей.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.prompt__pos2)}>
				<img src={PromptIconFour} alt="Иконка 4" loading="lazy" />
				<p>Клиенты должны видеть, что за проектом стоят живые люди</p>
			</div>
		),
	},
	{
		heading: "Соусы из 20 ингредиентов — такое дома сообразить сложно",
		textarea: (
			<span>
				<i>Настя:</i> Изначально мы рассчитывали, что к нам будут приходить
				молодые люди, которые ведут здоровый образ жизни. В итоге у нас очень
				разная аудитория. Мне кажется, это люди, которые приходят в поисках
				новых вкусов и неожиданных сочетаний. Они как первооткрыватели.
				Возможно, им хочется почувствовать атмосферу путешествия — как будто ты
				переместился в другое место.
				<br />
				<br />
				Я всегда вспоминаю мою любимую гостью. Ей, наверное, лет 60. Она пробует
				все наши новинки, всегда даёт обратную связь. Вроде людям в таком
				возрасте должно быть сложно пробовать и открывать что-то новое, но
				оказывается, что это не так. Поэтому наша аудитория — люди всех
				возрастов, а не только молодежь.
				<br />
				<br />
				Поке в первую очередь — про открытия и неожиданные вкусы. Кажется, в нём
				нет ничего необычного — взял рис и рыбу, вот и поке получился. На самом
				деле, поиск сочетаний вкусов — целый квест. Если сделать поке дома,
				никогда не получишь его таким как в «О, мой боул». Например, наши соусы
				состоят из 20 ингредиентов. Достаточно сложно сообразить такое дома.
				<br />
				<br />
				<i>Андрей:</i> Мы много времени уделяем качеству продуктов. Поиск
				поставщиков никогда не останавливается. Я всегда ищу лучший тунец,
				креветки, рис и другие продукты. Мне важно, чтобы мы готовили из лучших
				ингредиентов. Например, рыба у нас максимально свежая — вчера её
				выловили, а сегодня она в тарелке у гостя. Авокадо можно заказывать
				у поставщика в Петербурге, но когда в Доминикане сезон, мы
				заморачиваемся и возим его оттуда, хотя это достаточно сложно.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeTwo, styles.prompt__pos3)}>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={PromptImageTwoFullSize}
						className={styles.media}
					>
						<img src={PromptImageTwo} alt="меню на айпаде" loading="lazy" />
					</a>
				</Fancybox>

				<p>
					Всё меню — в два
					<br />
					клика на айпаде
				</p>
			</div>
		),
	},
	{
		heading: "Обратная связь помогает расти",
		textarea: (
			<span>
				<i>Настя:</i> Мы прикладываем много усилий, чтобы каждый гость был
				доволен, но негатив всё же случается. Первые два года было очень
				болезненно. Напишут один негативный отзыв и твой фокус переключается на
				него — не обращаешь внимания на другие 99 положительных. Сейчас обратная
				связь так не ранит. Стараюсь извлечь пользу даже неконструктивного
				«Все плохо, я больше никогда не приду».
				<br />
				<br />
				Обратная связь помогает нам расти. Например, мы видим, что за последние
				месяцы у нас есть три похожих отзыва про долгое ожидание заказа. Значит
				скорость отдачи провисает. Надо найти причину и исправить ситуацию.
				Возможно, вышел стажер или что-то ещё произошло. Недавно у нас появилась
				управляющая. Обратной связью теперь занимается она, но я всё равно
				просматриваю отзывы и слежу, что на них отвечает команда.
			</span>
		),
		prompt: (
			<div className={styles.promptTypeOne}>
				<img src={PromptIconFive} alt="Иконка 5" loading="lazy" />
				<p>
					Прикладываем много усилий, чтобы каждый был доволен, но негатив
					случается
				</p>
			</div>
		),
		align: styles.alignEnd,
	},
	{
		heading: "Про систему учёта и автоматизации в кафе",
		textarea: (
			<span>
				<i>Андрей:</i> Система автоматизации помогает контролировать поступление
				и списание товара, взаимодействовать с гостем. Изначально мы работали на
				неплохой программе, но она требовала большого участия и отдельного
				специалиста. Нужно было купить специализированное оборудование, нанять
				человека, который будет во всём разбираться, заниматься внедрением и
				поддержкой, устранять ошибки и устанавливать обновления.
				<br />
				<br />
				Стал изучать альтернативы и остановился на Quick Resto. Мне понравилась,
				что всё реализовано на iOS, не нужно покупать специализированное
				оборудование, можно пользоваться айпадом. Дружелюбный, интуитивно
				понятный интерфейс — любой сотрудник быстро научится работать с ним.
				Отдельный человек для поддержки системы не нужен.
				<br />
				<br />
				<i>Настя:</i> С Quick Resto мы начали работать во время пандемии.
				Заведение было закрыто для посещений, и я помню этот момент счастья,
				когда большой моноблок сменяется планшетом. Стоишь с айпадом на улице и
				принимаешь заказ. Всё интуитивно понятно, удобная аналитика. Система
				позволяет отследить каждую деталь, например, заметить, что себестоимость
				повысилась и выяснить, из-за чего это произошло. Дальше можно подумать,
				как её снизить, не теряя в качестве.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeTwo, styles.prompt__pos4)}>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={PromptImageThreeFullSize}
						className={styles.media}
					>
						<img
							src={PromptImageThree}
							alt="интерьер зала кафе"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>
					Зал с сёрфингом
					<br />
					и велосипедом
				</p>
			</div>
		),
	},
	{
		heading: "Партнёрство — непростая вещь",
		textarea: (
			<span>
				<i>Настя:</i> Кажется, что вдвоём проще, но если вы не умеете
				договариваться и делить зоны ответственности, это будет тормозить разные
				процессы. Прежде чем начать работать с партнёром, нужно триста раз
				подумать.
				<br />
				<br />
				Нам повезло — мы с Андреем дополняем друг друга, у нас разные сильные
				стороны. Например, Андрей мастерски умеет договариваться. Если мне
				назовут цену, я на неё соглашусь, а Андрей всегда ведёт переговоры. Я
				знаю, если нам нужен какой-то продукт, он найдёт лучшее качество по
				самой выгодной цене, которая только возможна. Я так сделать не смогу.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.prompt__pos5)}>
				<img src={PromptIconSix} alt="Иконка 6" loading="lazy" />
				<p>Прежде чем начать работать с партнёром, нужно триста раз подумать</p>
			</div>
		),
		mediaContent: (
			<div className={styles.mediaContent}>
				<Fancybox>
					<a
						data-fancybox="image7"
						href={MediaContentFour}
						className={styles.media}
					>
						<img
							src={MediaContentFour}
							alt="владельцы кафе oh my bowl"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p>Нам повезло — мы дополняем друг друга</p>
			</div>
		),
	},
	{
		heading: "Советы начинающим предпринимателям от Андрея",
		textarea: (
			<span>
				<strong>Посчитайте финмодель.</strong> Несколько раз, в трёх сценариях.
				Обязательно заложите негативный вариант развития событий и посмотрите,
				выживет ли ваше кафе в таких условиях.
				<br />
				<br />
				<strong>Занимайтесь маркетингом.</strong> Сегодня, когда Инстаграм* в
				России не работает, надо искать альтернативы. Они всегда есть, нужно
				экспериментировать и находить варианты.
				<br />
				<br />
				<strong>Делайте продукт, который вас вдохновляет.</strong> Без этого
				будет сложно преодолеть трудности, а они точно будут.
			</span>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.prompt__pos6)}>
				<img src={PromptIconSeven} alt="Иконка 7" loading="lazy" />
				<p>Только улучшая процессы, можно развивать бизнес</p>
			</div>
		),
	},
	{
		heading: "Советы начинающим предпринимателям от Насти",
		textarea: (
			<span>
				<strong>Перенимайте опыт у людей, которые уже что-то открыли.</strong>{" "}
				Напишите, спросите совет, задайте вопросы. Есть большая вероятность, что
				вам ответят. Становится чуть проще, когда ты можешь опереться на чей-то
				опыт.
				<br />
				<br />
				<strong>
					Закладывайте в бюджет на запуск непредвиденные расходы.
				</strong>{" "}
				Посчитали сумму, смело плюсуйте 30%, а лучше — 50%.
				<br />
				<br />
				<strong>Не бойтесь делиться проблемами.</strong> Когда мы начали вести
				Инстаграм*, я думала, что буду просто показывать внутрянку. Потом решила
				рассказывать ещё и о проблемах, с которыми мы сталкиваемся. Тогда
				случился шквал поддержки. Оказалось, людям не всё равно — они пишут,
				поддерживают, готовы помогать. Это важно почувствовать, когда ты только
				начинаешь что-то делать.
				<br />
				<br />
				<strong>Не останавливайтесь в развитии.</strong> Три года назад мы были
				довольны, если сотрудники на кассе просто здороваются с гостями. Сейчас
				планка повысилась — нужно, чтобы вынесли воду, чтобы презентовали блюда,
				а не просто рассказали, что такое поке. Нет предела совершенству. Только
				улучшая процессы, можно развивать бизнес.
				<br />
				<br />* Соцсеть заблокирована в России, поскольку принадлежит компании
				Meta, которая признана экстремистской организацией
			</span>
		),
	},
]
