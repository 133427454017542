import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Главный баннер"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Главный баннер"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img src={DesktopMediaMainBanner} alt="Главный баннер" loading="lazy" />
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Как открыть кафе с поке-боулами без опыта в общепите. Кейс покетерии{" "}
				<span className={styles.quote}>«О, мой боул!»</span>
			</>
		),
	},
	heading:
		"Остаться без шефа за день до открытия и сделать рекордную кассу за месяц",
	textarea: (
		<>
			Основатели кафе-покетерии «О, мой боул!» Анастасия Чернова и Андрей Югай —
			о том, какие сложности преодолели при запуске своего первого проекта, что
			им помогло сделать рекордную кассу в первый месяц работы и как
			первооткрывателям избежать ошибок.
		</>
	),
	video: {
		src: "https://www.youtube.com/embed/budSXL48PI4",
		textarea: (
			<p className={styles.textarea}>
				Как превысить бюджет в 5 раз и окупиться в первый месяц
			</p>
		),
	},
}
