import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import debounce from 'lodash.debounce'

import { requestForAutoOpenRestFaster } from '../../apiRequests/callToAction'

import DefaultLayout from '../../layout/default'

import HeadingTextareaAndPrompt from '../../components/_V3/SecretPages/HeadingTextareaAndPrompt'
import MainBanner from '../../components/_V3/SecretPages/MainBanner'

import SocialNetworkBanner from '../../components/_V3/SecretPages/SocialNetworkBanner'

import ConsultationForm from '../../components/_V3/SecretPages/ConsultationForm'

import FooterCompact from '../../components/_V2/FooterCompact'

import SendPulseModal from '../../components/_V2/SendPulseModal'

import { mainBanner } from '../../pages-data/_V3/secret/ohmybowl/MainBanner/data'
import { headingTextareaAndPrompt } from '../../pages-data/_V3/secret/ohmybowl/HeadingTextareaAndPrompt/data'
import { socialNetworkBanner } from "../../pages-data/_V3/secret/SocialNetworkBanner/data"
import { consultationForm } from '../../components/_V3/SecretPages/ConsultationForm/data'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/secret.module.scss'

export default function OhMyBowl() {
  const metaTags = [
    {
      name: 'description',
      content: 'Основатели кафе-покетерии «О, мой боул!» Анастасия Чернова и Андрей Югай — о том, какие сложности преодолели при запуске своего первого проекта, что им помогло сделать рекордную кассу в первый месяц работы и как первооткрывателям избежать ошибок.',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/secret/ohmybowl/',
    },
    {
      property: 'og:title',
      content: 'Секретный ингредиент Oh, my bowl: остаться без шефа за день до открытия и сделать рекордную кассу',
    },
    {
      property: 'og:description',
      content: 'Основатели кафе-покетерии «О, мой боул!» Анастасия Чернова и Андрей Югай — о том, какие сложности преодолели при запуске своего первого проекта, что им помогло сделать рекордную кассу в первый месяц работы и как первооткрывателям избежать ошибок.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: 'https://quickresto.ru/static/pres-45aab977d36977d92eb637008a13bda5.png',
    },
  ]

  // Проверка страницы на скролл до 50% для показа попапа о подписке
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalStatus = () => {
    setIsModalOpen(!isModalOpen)
  }

  let isHalfScrolled = false
  const handleScroll = () => {
    const pageHeight = document.body.scrollHeight
    const pageHalfHeight = pageHeight / 2

    // Если скролл больше половины страницы то показываем попап
    if (window.scrollY >= pageHalfHeight && isHalfScrolled == false) {
      setIsModalOpen(true)

      isHalfScrolled = true
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll, 500))

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <DefaultLayout
      title="Секретный ингредиент Oh, my bowl: остаться без шефа за день до открытия и сделать рекордную кассу"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  styles.bg,
      )}
      linkCanonical="https://quickresto.ru/secret/ohmybowl/"
      isFooterEnable={false}
    >
      <MainBanner data={mainBanner} className={pageStyles.pageSection} />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[0]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[1]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[2]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[3]}
        className={classNames(
					  pageStyles.pageSection,
					  styles.sectionMarginTop25,
        )}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[4]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[5]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[6]}
        className={pageStyles.pageSection}
      />

      <SocialNetworkBanner
        data={socialNetworkBanner}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[7]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[8]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[9]}
        className={pageStyles.pageSection}
      />

      <HeadingTextareaAndPrompt
        data={headingTextareaAndPrompt[10]}
        className={pageStyles.pageSection}
      />

      <ConsultationForm
        data={consultationForm}
        className={pageStyles.pageSection}
        request={requestForAutoOpenRestFaster}
      />

      <FooterCompact className={styles.footer} />

      <SendPulseModal isOpen={isModalOpen} onClose={handleModalStatus} />
    </DefaultLayout>
  )
}
